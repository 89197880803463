import { render, staticRenderFns } from "./ConfigButtons.vue?vue&type=template&id=0527a0d7&scoped=true&"
import script from "./ConfigButtons.vue?vue&type=script&lang=js&"
export * from "./ConfigButtons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0527a0d7",
  null
  
)

export default component.exports